
const TermsOfRefinanceON = () => {
  return (
      <>
        <h1>LAW FIRM ENGAGEMENT AGREEMENT</h1>
        <div className="type__subtitle">
          Congratulations on your upcoming real estate refinance or new mortgage 
          and thank you for choosing{" "}
          <span style={{ fontWeight: "600" }}>
            DEEDED Law Professional Corporation
          </span>{" "}
          (the “Law Firm”, “we”, “our”, “us” or “DEEDED Law”) for your legal needs.
        </div>
        <div className="type__subtitle">
          Our contact information is as follows:
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: "600" }}>
            DEEDED Law Professional Corporation
          </span>
          <br />
            200- 2010 Winston Park Drive, Oakville, Ontario L6H 5R7
        </div>
        <div className="type__subtitle">
          Please review this agreement thoroughly. This agreement establishes 
          the lawyer-client relationship, and explains your rights, our 
          obligations, and other key points that you’ll need to understand about 
          your transaction, rights and protection of your personal data.
        </div>
        <div className="type__subtitle">
          If you do not understand any of the terms or language in this 
          agreement, please contact us and we will gladly explain or answer any 
          questions you may have. You are also welcome to obtain independent 
          legal advice from another lawyer before entering into this agreement.
        </div>
        <div className="type__title">
          RELATIONSHIP BETWEEN LAW FIRM AND DEEDED TECHNOLOGY PLATFORM
        </div>
        <div className="type__subtitle" style={{ fontWeight: "600" }}>
          DEEDED Law is separate from 
          and operates independently of Deeded, Inc., which operates the 
          deeded.ca website and app.Deeded.ca (the “Platform”).
        </div>
        <div className="type__subtitle">
          To assist you with your real estate transaction, DEEDED Law engages 
          Deeded, Inc. as a third-party service provider to leverage its 
          technology Platform, which assists DEEDED Law in facilitating your 
          real estate transaction, and for certain administrative support 
          services.
        </div>
        <div className="type__subtitle">
          Electronically accepting this engagement agreement establishes a 
          lawyer-client relationship between you and DEEDED Law and confirms 
          that you will receive legal services and legal advice respecting your 
          real estate transaction from DEEDED Law.
        </div>
        <div className="type__subtitle">
          DEEDED Law independently exercises complete control over, and is 
          solely responsible for, the provision of legal advice, opinions, or 
          recommendations about your legal rights, obligations, remedies, 
          strategies, and options; the selection and completion of forms and 
          other documents; and the application of the law to the facts of your 
          situation.
        </div>
        <div className="type__subtitle">
          Deeded, Inc. is <span style={{ textDecoration: "underline" }}>not</span>{" "}
          a party to the lawyer-client relationship and does not provide any 
          legal services or advice. Deeded, Inc. may, however, act as an 
          intermediary between you and DEEDED Law or other Business Partners 
          (as that term is defined in Deeded Inc’s{" "}
          <span style={{ textDecoration: "underline" }}>Terms of Use</span> and{" "}
          <span style={{ textDecoration: "underline" }}>Privacy Policy</span>,
          which govern your relationship with Deeded, Inc. and usage of its 
          Platform).
        </div>
        <div className="type__subtitle">
          Deeded Inc. may also provide administrative services or assistance to 
          DEEDED Law such as administrative support, clerical support, 
          technical support, scheduling support, access to certain software or 
          databases, customer service support, and marketing support.
        </div>
        <div className="type__subtitle">
          In engaging Deeded, Inc. to use the Platform and for the provision of 
          the above-mentioned services, DEEDED Law has entered into strict 
          data processing agreements with Deeded, Inc. and its 
          staff, but in rare circumstances the assistance provided by Deeded, 
          Inc. may nevertheless lead to a loss of solicitor-client privilege.
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: 600 }}>
            By entering into this agreement, you confirm that you consent to DEEDED 
            Law providing your information—including your personal 
            information and information concerning the nature and status of your 
            real estate transaction—to Deeded, Inc. for the purpose of and to 
            the extent necessary to facilitate your real estate transaction by 
            leveraging the Deeded, Inc. Platform and administrative services.
          </span>{" "}
          You further confirm that providing this consent does <span style={{ textDecoration: "underline" }}>not</span>{" "} indicate any
          intention to waive solicitor-client privilege over your communications 
          with DEEDED Law—it is provided only to enable DEEDED Law to access 
          and utilize technology and administrative services that assist in its 
          provision of legal services to you.
        </div>
        <div className="type__subtitle">
          Deeded, Inc. receives remuneration from DEEDED Law for the use of 
          its technology and any administrative services rendered.
        </div>
        <div className="type__subtitle">
          Fees for legal services rendered are paid directly to DEEDED Law, 
          per DEEDED Law’s account provided to you
        </div>
        <div className="type__subtitle">
          If you have any questions about your fees and payment or the use and 
          disclosure of your information, please do not hesitate to contact us 
          before entering into this agreement.
        </div>
        <div className="type__title">SCOPE OF SERVICES</div>
        <div className="type__subtitle">
          During the retainer, we have authority to act for and on behalf of you 
          in connection with matters related to your Real Estate and/or Mortgage 
          transaction and to conduct such matters in all respects. Except where 
          you have specifically instructed otherwise, we may act on your behalf 
          and bind you in all necessary and related legal forms and procedures 
          within reason and we will be expected to take all steps reasonably 
          necessary to further and protect your interests.
        </div>
        <div className="type__title">CLOSING DATE</div>
        <div className="type__subtitle">
          The closing date of your transaction will be listed in the mortgage 
          documents and may be amended by requesting a change with your lender 
          where they will send our office revised mortgage documents. Your bank 
          representative or mortgage broker should be able to assist with any 
          amendments to the closing in the early stages of your transaction. 
          Please keep our office informed of any delays or changes in the 
          closing date.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Additional Fee for Delays
        </div>
        <div className="type__subtitle">
          If any delays are caused by you as a result of any reason in your 
          control (i.e. where you fail to communicate with us, or you fail to 
          provide information in a timely manner), then we reserve the right to 
          charge an additional administrative fee.
        </div>
        <div className="type__title">RETAINER AT AN END</div>
        <div className="type__subtitle">
          Upon the completion of the closing of your real estate transaction, 
          our retainer will be at an end. Our services will not include any legal 
          representation or advice with respect to any post-closing matters 
          including but not limited to, litigation or default proceedings. If we
          are able to assist with post-closing matters, we will provide you with 
          a new engagement/retainer letter along with estimated costs of our services 
          for representing you.
        </div>
        <div
          className="type__title"
          style={{ textDecoration: "underline", fontWeight: "700" }}
        >
          FEES AND DISBURSEMENTS
        </div>
        <div
          className="type__subtitle"
          style={{ fontWeight: "600", fontSize: "1.5rem" }}
        >
          In some real estate and/or mortgage transactions, there may be 
          unforeseen issues and/or difficulties that arise as a result of 
          matters beyond our law firm's control. DEEDED 
          Law reserves the right to increase fees to reflect any 
          additional time expended with the completion of your transaction and 
          will contact you to discuss the same.
        </div>
        <div
          className="type__title"
          style={{
            fontStyle: "italic",
            textDecoration: "none",
            fontWeight: "600",
          }}
        >
          Payment to Deeded Law, in trust
        </div>
        <div className="type__subtitle">
          Before your closing date, you may have to provide DEEDED Law with 
          sufficient funds by or bank draft, deposited to our trust account 
          or wire transfer to complete your transaction that are not covered 
          by your mortgage refinance. Please note that we do not accept any 
          funds in cash, including our retainer. 
          These funds include the amount owing to government agencies or to other 
          lenders plus legal fees, disbursements and adjustments for extra 
          expenses and taxes as outlined above. We will advise you prior to 
          closing of the total funds required.
        </div>
        <div
          className="type__title"
          style={{
            fontStyle: "italic",
            textDecoration: "none",
            fontWeight: "600",
          }}
        >
          Disbursements
        </div>
        <div className="type__subtitle">
          Disbursements are expenses that your lawyer charges you for 
          out-of-pocket expenses that you would otherwise be required to pay if 
          you handled the transaction yourself. Because your lawyer generally 
          pays these expenses, the lawyer requires you to reimburse them by 
          including these items in their account. Examples of disbursements 
          include but are not limited to:
        </div>
        <div className="type__subtitle">
          <ul>
            <li>
              Execution Certificates – Fees paid to the government to search for 
              writs registered against names of the parties in the transaction
            </li>
            <li>
              Law society levy – Fees charges per transaction by the law society
            </li>
            <li>
              Parcel Search – Charges to search the land titles system or land 
              registry
            </li>
            <li>Postage and couriers and/or file storage</li>
            <li>
              Registration of deed and mortgage fee – Paid to the Provincial 
              government to register new owner of property on title if 
              applicable, and to register a new mortgage
            </li>
            <li>
              Discharges of mortgages on title – Paid to Ontario government to 
              discharge mortgages on title where the lender is requiring our 
              firm to register the discharge
            </li>
            <li>
              Software transaction charge – For the specialized software used by 
              your lawyer
            </li>
          </ul>
        </div>
        <div className="type__subtitle">
          If your property has two or more units, is commercial, or is
          located in a rural environment; then additional enquires may also
          be necessary.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Other Charges
        </div>
        <div className="type__subtitle">
          You will also be responsible for office charges allocated to your file. 
          These include charges for conveyancer/paralegal time, word processing 
          charges, computer costs, in-house photocopying and faxes.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Taxes
        </div>
        <div className="type__subtitle">
          You will be charged the applicable taxes on fees and on some disbursements 
          or other charges.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Cancellation Policy
        </div>
        <div className="type__subtitle">
          If for any reason the transaction is cancelled by you, the lender, failure 
          to complete the Client Identification and Verification process or due to 
          a conflict of interest that cannot be resolved, you acknowledge and agree 
          that you will be liable and shall pay the amount of your legal fees and 
          disbursements incurred by DEEDED Law as incurred prior to the cancellation.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Cancellation Policy - Private Lender Transactions
        </div>
        <div className="type__subtitle">
        Should the transaction be instructed and cancelled/not closed for any reason, 
        a fee of {" "}<span style={{ fontWeight: 600 }}>$295.00 {" "}</span> plus taxes and a lender interest adjustment fee will be 
        charged to the borrower.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Rush Fee
        </div>
        <div className="type__subtitle">
          There is an additional legal fee to provide this service and a retainer 
          is to be provided prior to starting the file. We have no way to ensure 
          that other service providers will deliver in the short time frame requested.
        </div>
        <div
          className="type__title"
          style={{ textDecoration: "none", fontStyle: "italic" }}
        >
          HOLDBACK OF FUNDS
        </div>
        <div className="type__subtitle">
          Please note there is a fee applicable for any holding of funds after 
          the transaction closes or the transaction does not close for any 
          reason. A <span style={{ fontWeight: 600 }}>$250.00</span> plus GST and 
          provincial sales tax (where applicable) fee will be deducted at the 
          time of the release of the holdback.
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: 600 }}>Note:</span>  
          Our standard fees are applicable for all work completed even 
          if the transaction does not close, regardless of whether you were in 
          breach of contract or not, provided our firm has completed work on your 
          file. Additional fees are also applicable for any additional payouts 
          beyond paying off your mortgage and net proceeds to yourself.
        </div>
        <div className="type__title">THIRD PARTY PAYMENT PROCESSOR</div>
        <div className="type__subtitle">
          I/We acknowledge that DEEDED Law uses a Third 
          Party Payment Processor to pay accounts and debts as required to close the 
          transaction. I/We explicitly provide consent to DEEDED Law 
          to disburse funds to the Third Party Payment Processor as necessary. 
          I/We further acknowledge that there may be delays in the banking system and that 
          DEEDED Law is not liable for any losses, damages, 
          and/or claims due to any delays in funding/disbursement of payment. I/We the 
          undersigned agree to adjust any shortfall that may be required to remedy any 
          payments that are required for the completion of the transaction.
        </div>
        <div className="type__title">INTEREST ON UPAID ACCOUNTS</div>
        <div className="type__subtitle">
          Under the Ontario Solicitors Act, bills for fees and disbursements 
          remaining unpaid more than 30 days after the bill is delivered, will 
          bear interest at a prescribed rate as specified on the bill. Payment 
          is due on all of our accounts when rendered. If any account is not 
          paid within 30 days, interest will be charged on the outstanding 
          balance at a rate of <span style={{ fontWeight: 600 }}>5%</span> per 
          annum from the date of the account, until paid.
        </div>
        <div className="type__title">TITLE</div>
        <div className="type__subtitle">
          You must provide us with the legal name or names you want on the 
          Transfer (please note that initials cannot be used) as well as the 
          dates of birth for each. There are several options as to how title to 
          the property may be recorded.
        </div>

        <div className="type__title">MORTGAGE</div>
        <div className="type__subtitle">
          Please ensure that DEEDED Law receives 
          mortgage instructions from your lender as early as possible since a 
          majority of your file cannot be processed until we have a full 
          understanding of financing and the requirements of the lender.
        </div>

        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Additional Fee - Late Mortgage Instructions
        </div>
        <div className="type__subtitle">
          Please note, we charge a $150.00 plus HST fee premium if we receive 
          the lender mortgage instructions less than 48 hours before the closing 
          date. If mortgage instructions are received within less than 48 hours, 
          our office is forced to delay other files by diverting resources 
          and/or we are required to spend time outside of working hours to rush 
          to complete all the required legal tasks prior to closing. A large 
          number of documents and tasks related to the closing rely on having 
          mortgage instructions in hand for important information. As such, 
          please ensure that you finalize your financing with your lender as 
          soon as possible before the closing date to ensure we receive mortgage 
          instructions in a timely fashion.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Additional Fee - "B/Alternative" Lenders and Private Mortgages
        </div>
        <div className="type__subtitle">
          We charge an additional $100.00 plus HST fee premium for mortgages 
          that are from "B/Alternative" Lenders or Private Lenders. "B" Lenders 
          generally are smaller financial institutions, lending companies and 
          investment companies that are not chartered banks.
        </div>
        <div className="type__subtitle">
          We do not charge a premium for the large chartered Canadian banks and 
          class "A" lenders such as TD Canada Trust, Bank of Montreal, National 
          Bank, Royal Bank, Bank of Nova Scotia (Scotiabank), etc.
        </div>
        <div className="type__subtitle">
          The reason for this premium in fees is because, generally, smaller 
          lenders do not have physical branches, and usually ask for 
          many more requirements and documents to be processed by our law firm 
          as compared to the larger class A lenders. Our premium is to cover the 
          extra resources we need to invest for files with mortgages that 
          request more information than that of a standard file.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Additional Fee – Debt payouts
        </div>
        <div className="type__subtitle">
          In some instances, your lender will require us to pay your creditors 
          from the proceeds of your mortgage refinance. To administer this, we 
          will ask you for copies of statements from the creditors to which we 
          are to pay. We will issue payment to these creditors through EFT or 
          cheque, as required. Please note that we charge per debt payout in 
          order to cover additional administrative costs. While we strive to 
          promptly forward all payments to your creditors shortly after the 
          closing of your mortgage refinance, DEEDED Law cannot guarantee 
          receipt of payment by a creditor and cannot not be held liable for any 
          additional fees, interest, penalties, or per-diem charged by your 
          creditor due to delay in payment. Should you be informed of a delay 
          in payment to a creditor, you are responsible for notifying us within 
          24 hours so that we can investigate and work to rectify.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Additional Fee – More than one mortgage
        </div>
        <div className="type__subtitle">
          If you require more than one mortgage to be registered or discharged, 
          you will incur extra fees per additional mortgage. Should your 
          property have a notice of security interest on title that requires a 
          postponement, you will incur extra fees to process such 
          postponement(s)
        </div>
        <div className="type__title">
          STATUS CERTIFICATION (FOR CONDOMINIUMS)
        </div>
        <div className="type__subtitle">
          If the property you are refinancing is a condominium, we will require 
          a review of the condo’s status certificate as a condition of closing 
          set by your lender. You will be responsible for obtaining a current 
          status certificate and all associated costs with obtaining the status 
          certificate.
        </div>
        <div className="type__title">INSURANCE</div>
        <div className="type__subtitle">
          Your lender will require proof of home insurance prior to closing with 
          your new lender named as “loss payee” on the policy. Obtaining or 
          modifying your insurance policy is solely your responsibility. Any 
          closing delays and associated fees caused by not having adequate proof 
          of insurance are your responsibility.
        </div>
        <div className="type__title">INSURING TITLE TO YOUR PROPERTY</div>
        <div className="type__subtitle">
          Lawyers acting for clients in Ontario are required as part of their 
          responsibilities to advise their clients as to their options with 
          respect to insuring the title to their property. In almost all 
          transactions title insurance will be required.
        </div>
        <div className="type__subtitle">
          We will be ordering a policy from either First Canadian Title, Stewart 
          Title or Chicago Title for closing. You are responsible for the costs 
          and associated taxes of a one-time premium for the title insurance 
          policy.
        </div>

        <div className="type__title">INFORMATION WE NEED FROM YOU</div>
        <div className="type__subtitle">
          During the course of your closing, we will require information and 
          certain documents from you in order to facilitate a timely closing of 
          your transaction.
        </div>
        <div className="type__subtitle">
          You are responsible for the timely delivery of information and 
          documents to our office either by electronic, facsimile or couriering 
          documents. Any delays or additional fees, penalties, or interest 
          incurred due to unreasonable delays or withholding required documents 
          or information from you, shall be solely your responsibility.
        </div>
        <div className="type__title">REMOTE / VIRTUAL SIGNING</div>
        <div className="type__subtitle">
          Effective August 1, 2020, Ontario commissioning legislation permits 
          the practice of remote commissioning. Remote commissioning is an 
          authentication and signature process for taking affidavits and 
          statutory declarations that uses audio-visual technology.
        </div>
        <div className="type__subtitle">
          At DEEDED Law, we use secure remote commissioning technology to make 
          the signing process safe, easy and secure. Shortly before your 
          closing, we will arrange a day and time to meet with you virtually 
          using our remote commissioning technology. We will need each person 
          whose name will appear on the Mortgage and Transfer to attend the 
          signing, if applicable. If any person whose name will appear on the 
          Mortgage and Transfer will not be available at that time, please 
          advise us as soon as possible as alternative arrangements may have to 
          be made.
        </div>
        <div className="type__subtitle">
          In a remote commissioning procedure, you will be connected by live 
          video conference. The video and signing session will be recorded for 
          our records, along with information about your location, identity, and 
          other measures used to prevent fraud and protect all parties from 
          future disputes. Any recordings, or information captured during, 
          before, or after the remote commissioning session is the sole property 
          of DEEDED Law and shall become part of your 
          client file for record keeping purposes and to comply with 
          regulations.
        </div>
        <div className="type__subtitle">
          In order to attend a remote commissioning session, you will require 
          access to a computer, a high-speed internet connection and a 
          touch-screen device such as a mobile phone. If you do not have access 
          to required technology, please inform us immediately so that we can 
          seek alternative options.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Additional Fee – More than one signing appointment
        </div>
        <div className="type__subtitle">
          Our fees include one virtual signing appointment and requires all 
          borrowers, guarantors or any other parties required to sign documents 
          to attend at the scheduled time for signing. Should one or more 
          parties fail to attend, are late to the signing meeting, fail to 
          produce valid identification, or are having technology issues that 
          cannot be resolved in the beginning of the signing meeting, you may be 
          charged for an additional signing appointment.
        </div>
        <div className="type__subtitle">
          Should you require a visit by a lawyer or commissioner of oaths to 
          your home or office in order to sign the documents, you may incur 
          additional fees.
        </div>
        <div className="type__title">IDENTIFICATION</div>
        <div className="type__subtitle">
          The Law Society and your mortgage lender require us to verify your 
          identity. We must also ensure your matter doesn’t conflict with another 
          matter or client.
        </div>
        <div className="type__subtitle">
          At our meeting, we will require each person, to bring two pieces of 
          identification, one of which must be Canadian/Federal or Provincial 
          government-issued photo identification such as a driver's licence or 
          passport. Unfortunately,{" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
          a health card is not acceptable identifi cation.{" "}
          </span>
          If you have any questions about what is an acceptable form of 
          identification or if you do not have Canadian/Federal or Provincial 
          photo identification, please contact us as soon as possible.
        </div>
        <div
          className="type__title"
          style={{
            textDecoration: "none",
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          An expired or non-valid identification will not be accepted.
        </div>
        <div
          className="type__title"
          style={{
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          Client Identification and Verification
        </div>
        <div className="type__title">
          DEEDED Law retains the services of a third-party to assist with the 
          Client Identifi cation and Verifi cation process {" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
            (“IDV”) {" "}
          </span>
          to comply with 
          Law Society Rules. All clients are required to complete the IDV process, 
          and if we are unable to comply with the IDV Rules of the Law Society for 
          any reason, the client will be required to meet in-person with a local 
          Notary or Lawyer to complete the IDV process. If we are unable to complete 
          the IDV process, we will no longer be able to act on the transaction. 
        </div>
        <div className="type__subtitle">
          The Client is responsible for all fees associated with the IDV process, 
          including all third-party fees.
        </div>
        <div className="type__title">TERMINATION OF SERVICES</div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          By you, the client
        </div>
        <div className="type__subtitle">
          You have the right to terminate our services to you upon written 
          notice to our firm.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          By us, the Solicitor(s)
        </div>
        <div className="type__subtitle">
          Subject to our obligations to you to maintain proper standards of 
          professional conduct, we reserve the right to terminate our services 
          to you for good reasons which include, but are not limited to:
        </div>
        <div className="type__subtitle">
          <ul>
            <li>if you fail to cooperate with me in any reasonable request;</li>
            <li>if our continuing to act would be unethical or impractical;</li>
            <li>improper behavior or aggression;</li>
            <li>if there is a serious loss of confidence between us and you;</li>
            <li>if or retainer has not been paid;</li>
            <li>you are dishonest; or</li>
            <li>if you fail to pay our accounts when rendered.</li>
          </ul>
        </div>
        <div className="type__subtitle">
          If you terminate our services or we withdraw our services, you would 
          only have to pay fees, disbursements, and expenses up until the time 
          we stopped acting for you.
        </div>
        <div className="type__subtitle">
          We would also have to withdraw our services if we learned of a{" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
            conflict of interest
          </span>{" "}
          that would make it unethical for us to continue to act for you. A 
          conflict of interest occurs when what is best for one of the clients 
          of our firm somehow is not best for or hurts another of our clients. 
          If we have to withdraw our services for you because of a conflict of 
          interest, you will only have to pay our fees and expenses up to the 
          time we stopped acting for you.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Fees Collected On Terminated Or Cancelled Matters With Flat Rate Fees
        </div>
        <div className="type__subtitle">
          Please note for matters that have flat rate fees such as Real Estate 
          closings, we reserve the right to charge for our time and resources 
          spent up to the termination of the solicitor-client contract.
        </div>
        <div className="type__subtitle">
          Terminations may also occur upon a client’s failure to close as a 
          result of a lack of funds or other breach of contract or due to the 
          client not providing information and co-operation as necessary. DEEDED 
          Law shall have the right to charge for its time and resources on files 
          that are terminated as a result of client negligence. For clarity, 
          DEEDED Law shall have the right to charge for its time and resources 
          for if a flat rate closing file fails to close for any reason.
        </div>
        <div className="type__title">
          ALTERNATIVE ATTORNEY IN CASE OF ABSENCE
        </div>
        <div className="type__subtitle">
          The Client acknowledges that the Lawyer has entered into an arrangement with 
          PulseLaw Real Estate Group Law Corporation (“PulseLaw”) to provide continuity of 
          legal representation in the event of the Lawyer’s planned or unplanned absence, 
          including but not limited to vacation, illness, incapacity, or other circumstances 
          that prevent the Lawyer from fulfilling their professional duties.<br />
          In such instances, PulseLaw may assume responsibility for the Client’s legal matter, 
          ensuring continued representation and the timely handling of any legal requirements. 
          The Client agrees that PulseLaw and its designated lawyers may take necessary steps 
          to advance the Client’s interests in the real estate matter during the Lawyer’s absence.<br />
          The Lawyer will make reasonable efforts to notify the Client in advance of any planned 
          absences and confirm the temporary transition of representation to PulseLaw. In the 
          case of an unplanned absence, such as sudden illness, incapacity, or if the Lawyer’s 
          law corporation ceases to exist, PulseLaw may act as necessary without prior notice to 
          the Client to prevent any prejudice to the Client’s matter.
          This designation does not create a new retainer agreement between the Client and PulseLaw, 
          except to the extent necessary to ensure ongoing legal representation. Fees and costs will 
          continue to be governed by this retainer agreement, unless otherwise agreed upon in writing 
          between the Client and PulseLaw.<br />
          The Lawyer remains responsible for overseeing the transition of representation, except in 
          cases of extended incapacity, dissolution of the Lawyer’s law corporation, or other 
          unforeseen unavailability, in which case the Client may seek independent legal 
          representation if desired.
        </div>
        <div className="type__title">
          YOUR AGREEMENT TO THE RETAINER AGREEMENT FOR SOLICITOR AND CLIENT
          RELATIONSHIP
        </div>
        <div className="type__subtitle">
          By checking the box, you are acknowledging that you have read this
          retainer agreement in full and that you agree to abide by the terms
          set out above.
        </div>
        <div className="type__subtitle">
          If you do not agree to this retainer agreement, there is no
          solicitor-client relationship. Please advise us immediately of any
          questions, concerns, comments or if you do not agree to this retainer
          agreement.
        </div>
      </>
  );
};

export default TermsOfRefinanceON;
