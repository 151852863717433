const TermsOfPurchaseON = () => {
  return (
      <>
        <h1>LAW FIRM ENGAGEMENT AGREEMENT</h1>
        <div className="type__subtitle">
        Congratulations on your upcoming real estate purchase and thank you 
        for choosing{" "}
          <span style={{ fontWeight: "600" }}>
          DEEDED Law Professional Corporation
          </span>{" "}
          ( “Law Firm”, “we”, “our”, or “us” or “DEEDED Law”) for your legal needs.
        </div>
        <div className="type__subtitle">
          Our contact information is as follows:
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: "600" }}>
            DEEDED Law Professional Corporation
          </span>
          <br />
          200- 2010 Winston Park Drive, Oakville, Ontario L6H 5R7
        </div>
        <div className="type__subtitle">
          Please review this agreement thoroughly. This agreement establishes 
          the lawyer-client relationship, and explains your rights, our 
          obligations, and other key points that you’ll need to understand about 
          your transaction, rights and privacy.
        </div>
        <div className="type__subtitle">
          If you do not understand any of the terms or language in this 
          agreement, please contact us and we will gladly explain or answer any 
          questions you may have. You are also welcome to obtain independent 
          legal advice from another lawyer before entering into this agreement.
        </div>
        <div className="type__title">
          RELATIONSHIP BETWEEN LAW FIRM AND DEEDED TECHNOLOGY PLATFORM
        </div>
        <div className="type__subtitle" style={{ fontWeight: "600" }}>
          DEEDED Law is separate from 
          and operates independently of Deeded, Inc., which operates the 
          deeded.ca website and app.Deeded.ca (the “Platform”).
        </div>
        <div className="type__subtitle">
          To assist you with your real estate transaction, DEEDED Law engages 
          Deeded, Inc. as a third-party service provider to leverage its 
          technology Platform, which assists DEEDED Law in facilitating your 
          real estate transaction, and for certain administrative support 
          services.
        </div>
        <div className="type__subtitle">
          Electronically accepting this engagement agreement establishes a 
          lawyer-client relationship between you and DEEDED Law and confirms 
          that you will receive legal services and legal advice respecting your 
          real estate transaction from DEEDED Law.
        </div>
        <div className="type__subtitle">
          DEEDED Law independently exercises complete control over, and is 
          solely responsible for, the provision of legal advice, opinions, or 
          recommendations about your legal rights, obligations, remedies, 
          strategies, and options; the selection and completion of forms and 
          other documents; and the application of the law to the facts of your 
          situation.
        </div>
        <div className="type__subtitle">
          Deeded, Inc. is <span style={{ textDecoration: "underline" }}>not</span>{" "}
          a party to the lawyer-client relationship and does not provide any 
          legal services or advice. Deeded, Inc. may, however, act as an 
          intermediary between you and DEEDED Law or other Business Partners 
          (as that term is defined in Deeded Inc’s{" "}
          <span style={{ textDecoration: "underline" }}>Terms of Use</span> and{" "}
          <span style={{ textDecoration: "underline" }}>Privacy Policy</span>,
          which govern your relationship with Deeded, Inc. and usage of its 
          Platform).
        </div>
        <div className="type__subtitle">
          Deeded Inc. may also provide administrative services or assistance to 
          DEEDED Law such as administrative support, clerical support, 
          technical support, scheduling support, access to certain software or 
          databases, customer service support, and marketing support.
        </div>
        <div className="type__subtitle">
          In engaging Deeded, Inc. to use the Platform and for the provision of 
          the above-mentioned services, DEEDED Law has entered into strict 
          data processing agreements with Deeded, Inc. and its 
          staff, but in rare circumstances the assistance provided by Deeded, 
          Inc. may nevertheless lead to a loss of solicitor-client privilege.
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: 600 }}>
            By entering into this agreement, you confirm that you consent to 
            DEEDED Law providing your information—including your personal 
            information and information concerning the nature and status of your 
            real estate transaction—to Deeded, Inc. for the purpose of and to 
            the extent necessary to facilitate your real estate transaction by 
            leveraging the Deeded, Inc. Platform and administrative services.
          </span>{" "}
          You further confirm that providing this consent does not indicate any 
          intention to waive solicitor-client privilege over your communications 
          with DEEDED Law—it is provided only to enable DEEDED Law to access 
          and utilize technology and administrative services that assist in its 
          provision of legal services to you.
        </div>
        <div className="type__subtitle">
          Deeded, Inc. receives remuneration from DEEDED Law for the use of 
          its technology and any administrative services rendered.
        </div>
        <div className="type__subtitle">
          Fees for legal services rendered are paid directly to DEEDED Law, 
          per DEEDED Law’s account provided to you.
        </div>
        <div className="type__subtitle">
          If you have any questions about your fees and payment or the use and 
          disclosure of your information, please do not hesitate to contact us 
          before entering into this agreement.
        </div>
        <div className="type__title">SCOPE OF SERVICES</div>
        <div className="type__subtitle">
          During the retainer, we have authority to act for and on behalf of you 
          in connection with such matters and to conduct such matters in all 
          respects. Except where you have specifically instructed otherwise, we 
          may act on your behalf and bind you in all necessary and related legal 
          forms and procedures within reason and we will be expected to take all 
          steps reasonably necessary to further and protect your interests.
        </div>
        <div className="type__subtitle">
          Under this agreement, our scope is limited to ensuring a successful 
          transfer of the title of your purchased property, which includes, but 
          not limited to reviewing your agreement of purchase and sale, due 
          diligence such as searching title of your purchased property, 
          communicating with the seller’s lawyer, registration of mortgages and 
          other charges (as applicable), administration of title insurance and 
          applicable taxes such as land transfer taxes.
        </div>
        <div className="type__subtitle">
          For any potential issues that may arise such as breach of contract or 
          post-closing issues, we will attempt to resolve any such issues in 
          good faith with parties involved in the transaction. Should we not be 
          able to reach agreement on any of such issues, we will recommend a 
          course of action, including potential litigation. Please note that we 
          do not practice litigation and you will have to engage separate 
          counsel if needed.
        </div>
        <div className="type__title">CLOSING DATE</div>
        <div className="type__subtitle">
          The closing date of your transaction will be listed in the Agreement 
          of Purchase and Sale and may be amended by an amendment form agreement 
          to the original Agreement of Purchase and Sale. Your real estate agent 
          should be able to assist with any amendments to the closing in the 
          early stages of your transaction. Please keep our office informed of 
          any delays or changes in the closing date.
        </div>
        <div className="type__title" style={{ fontStyle: "italic" }}>
          Additional Fee For Delays{" "}
        </div>
        <div className="type__subtitle">
          If any delays are caused as a result of you not having adequate 
          financing or funding available on the closing date or any other reason 
          for the delay which is not due to our action or omission, then we 
          reserve the right to charge an additional $350.00 plus GST and 
          provincial sales tax (where applicable) per extension.
        </div>
        <div className="type__title">RETAINER AT AN END</div>
        <div className="type__subtitle">
          Upon the completion of the closing of your real estate transaction, 
          our retainer will be at an end. Our services will not include any legal 
          representation or advice with respect to any post-closing matters 
          including but not limited to, litigation or default proceedings. If 
          we are able to assist with post-closing matters, we will provide you 
          with a new engagement/retainer letter along with estimated costs of our 
          services for representing you.
        </div>
        <div className="type__title">FEES AND DISBURSEMENTS</div>
        <div
          className="type__subtitle"
          style={{ fontWeight: "500", fontSize: "1.5rem" }}
        >
          In some real estate transactions, there may be unforeseen issues, 
          complexities, and/or difficulties that arise as a result of matters 
          beyond our law firm's control. DEEDED Law 
          reserves the right to increase fees to reflect any additional time 
          expended with the completion of your transaction and will contact you 
          to discuss accordingly.
        </div>
        <div className="type__title" style={{ fontStyle: "italic" }}>
          Land Transfer Tax
        </div>
        <div className="type__subtitle">
          In addition to these fees, you may be required to pay on closing 
          Land Transfer Tax (LTT). Please contact us and we can help 
          calculate how much LTT you will be required to pay on closing. If you 
          are a first-time homebuyer, please contact our office and advise us 
          accordingly as you may qualify for the first-time homebuyer rebate.
        </div>
        <div className="type__title" style={{ fontStyle: "italic" }}>
          Payment to Deeded Law
        </div>
        <div className="type__subtitle">
          Before your closing date, you may have to provide DEEDED Law with 
          sufficient funds by or bank draft, deposited to our trust account or 
          wire transfer to complete your transaction that are not covered by your 
          mortgage refinance. Please note that we do not accept any funds in cash, 
          including our retainer. These funds include the amount owing to government 
          agencies or to other lenders plus legal fees, disbursements and adjustments 
          for extra expenses and taxes as outlined above. We will advise you prior to 
          closing of the total funds required.
        </div>
        <div className="type__subtitle">
          Instructions will be provided by our office prior to closing 
          (approximately 2-3 days once we receive the Statement of Adjustments 
          from the Seller’s lawyer and confirmation of the net advance from your 
          lender) and must be strictly adhered.
        </div>
        <div className="type__title" style={{ fontStyle: "italic" }}>
          Disbursements
        </div>
        <div className="type__subtitle">
          Disbursements are expenses that your lawyer charges you for 
          out-of-pocket expenses that you would otherwise be required to pay if 
          you handled the transaction yourself. Because your lawyer generally 
          pays these expenses, the lawyer requires you to reimburse them by 
          including these items in their account. Examples of disbursements 
          include but are not limited to: postage, deliveries, software fees, 
          trust administration fees, government filing and search charges, and 
          the fees of agents who conduct investigations, searches and 
          registrations and all other reasonable out of pocket expenses.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Other Charges
        </div>
        <div className="type__subtitle">
          You will also be responsible for office charges allocated to your file. 
          These include charges for conveyancer/paralegal time, word processing charges, 
          computer costs, in-house photocopying and faxes.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Taxes
        </div>
        <div className="type__subtitle">
          You will be charged the applicable taxes on fees and on some disbursements or other charges.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Cancellation Policy
        </div>
        <div className="type__subtitle">
          If for any reason the transaction is cancelled by you, the lender, 
          failure to complete the Client Identification and Verification process 
          or due to a conflict of interest that cannot be resolved, you acknowledge 
          and agree that you will be liable and shall pay the amount of your legal 
          fees and disbursements incurred by DEEDED Law as incurred prior to the 
          cancellation.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Rush Fee

        </div>
        <div className="type__subtitle">
          There is an additional legal fee to provide this service and a retainer 
          is to be provided prior to starting the file. We have no way to ensure 
          that other service providers will deliver in the short time frame 
          requested.
        </div>
        <div className="type__title">HOLDBACK OF FUNDS</div>
        <div className="type__subtitle">
          Please note there is a fee applicable for any holding of funds after 
          the transaction closes or the transaction does not close for any 
          reason. A <span style={{ fontWeight: "600" }}>$250.00</span> plus GST 
          and provincial sales tax (where applicable) fee will be deducted at 
          the time of the release of the holdback.
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: "600" }}>Note:</span> Our standard fees are 
          applicable for all work completed even if the transaction does not 
          close, regardless of whether you were in breach of contract or not, 
          provided our firm has completed work on your file. Additional fees are 
          also applicable for any additional payouts beyond paying off your 
          mortgage and net proceeds to yourself.
        </div>
        <div className="type__title">THIRD PARTY PAYMENT PROCESSOR</div>
        <div className="type__subtitle">
          I/We acknowledge that DEEDED Law uses a Third 
          Party Payment Processor to pay accounts and debts as required to close 
          the transaction. I/We explicitly provide consent to DEEDED Law 
          to disburse funds to the Third Party Payment 
          Processor as necessary. I/We further acknowledge that there may be 
          delays in the banking system and that DEEDED Law 
          is not liable for any losses, damages, and/or claims due 
          to any delays in funding/disbursement of payment. I/We the undersigned 
          agree to adjust any shortfall that may be required to remedy any 
          payments that are required for the completion of the transaction.
        </div>
        <div className="type__title">INTEREST ON UPAID ACCOUNTS</div>
        <div className="type__subtitle">
          Bills for fees and disbursements remaining unpaid more than 30 days 
          after the bill is delivered, will bear interest at a prescribed rate 
          as specified on the bill. Payment is due on all of our accounts when 
          rendered. If any account is not paid within 30 days, interest will be 
          charged on the outstanding balance at a rate of 5% per annum from the 
          date of the account, until paid.
        </div>
        <div className="type__title">TITLE</div>
        <div className="type__subtitle">
          You must provide DEEDED Law with the legal 
          names that are to be registered on the Deed/Title to the property 
          (initials cannot be used) as well as the date of birth for each party.
        </div>
        <div className="type__subtitle">
          If one or more person’s name is to be on title, you will have to 
          choose how you will be taking title; choices include “Joint Tenants” 
          and “Tenants-in-Common”.
        </div>
        <div className="type__subtitle">
          If you choose Joint Tenants, in the event of a death of one of the 
          joint tenants, the survivor(s) will own the entire property by way of 
          right of survivorship. If you choose Tenants-in-Common, upon the death 
          of one of the co-owners, the person’s interest will pass onto his 
          Estate under his Will or in accordance with intestacy legislation if 
          there is no Will.
        </div>
        <div className="type__subtitle">
          Please note that if DEEDED Law Real acts for more 
          than one individual in the transaction, information received may not 
          be kept confidential between the parties. Should conflict arise 
          between you that cannot be resolved, DEEDED Law 
          may no longer be able to act on either of your behalf.
        </div>
        <div className="type__title">MORTGAGE</div>
        <div className="type__subtitle">
          Ensuring you have financing and all the required funds for closing in 
          a timely manner is of utmost importance. Ideally, you should attempt 
          to become pre-approved from a lender prior to firming up real estate 
          closing. This helps ensure you have enough money on closing to close 
          the transaction. Failure to have enough funds on a firm deal can lead 
          to you breaching the Agreement of Purchase Sale contract which may 
          lead to the loss of your deposit and potential legal liability as the 
          vendor will have the opportunity to sue for damages caused by the 
          failure to close. This can include but is not limited to legal fees, 
          real estate fees, financing charges on mortgages and bridge loans of 
          the vendor, losses from selling the home to a third party for a lower 
          amount after the breach, and more.
        </div>
        <div className="type__subtitle">
          It is your responsibility to notify the lender if the property is not 
          going to be immediately occupied as your principal residence or if you 
          are purchasing the property under a Joint Venture Agreement or any 
          other agreement that requires disclosure such as, renting the property 
          or making any additions, demolition or significant changes to the 
          property, etc.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          CONSENT TO ACT FOR BOTH BORROWER AND LENDER
        </div>
        <div className="type__subtitle">
          When obtaining a mortgage, DEEDED Law may be 
          representing both you and the lender at the time of closing your 
          purchase transaction. DEEDED Law has the 
          obligation to represent the interest of both the borrower and lender 
          in a transaction. As a result, all known material facts disclosed 
          fully to DEEDED Law and all other parties who 
          have an interest in this transaction including but not limited to the 
          Mortgage Lender(s) must be disclosed. Material facts include but are 
          not limited to all beneficial interests (including the interest of any 
          other party in the property), the intended and future use of the 
          property (if other than residential), tenancies, Joint Ventures, Rent 
          to Owns etc.
        </div>
        <div className="type__subtitle">
          In the event of a conflict between you and the Lender, which cannot be 
          resolved by mutual agreement, you agree to advise us thereof, and you 
          understand that DEEDED Law may reserve the 
          right to discontinue acting for either party in this matter.
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: "600" }}> BRIDGE LOANS </span> - If you are 
          buying and selling properties where the purchase occurs before your 
          sale and you are relying on sale proceeds to pay for the purchase, you 
          will need to obtain a bridge loan to cover the difference that you 
          need to close the purchase file if you do not have personal funds to 
          cover the difference before the sale.
        </div>
        <div className="type__subtitle" style={{ fontWeight: "600" }}>
          DEEDED Law charges an additional $150.00 and disbursements for each bridge loan.
        </div>
        <div className="type__subtitle">
          Please ensure that DEEDED Law receives 
          mortgage instructions from your lender as early as possible since a 
          majority of your file cannot be processed until we have a full 
          understanding of financing and the requirements of the lender.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Additional Fee - Late Mortgage Instructions
        </div>
        <div className="type__subtitle">
          Please note, we charge a $150.00 plus GST and provincial sales tax 
          (where applicable) fee Premium if we receive the lender mortgage 
          instructions less than 48 hours before the closing date. If mortgage 
          instructions are received with less than 48 hours, our office is 
          forced to delay other files by diverting staff resources and/or we are 
          required to spend time outside of working hours to rush to complete 
          all the required legal tasks prior to closing. A large number of 
          documents and tasks related to the closing rely on having mortgage 
          instructions in hand for important information. As such, please ensure 
          that you finalize your financing with your lender as soon as possible 
          before the closing date to ensure we receive mortgage instructions in 
          a timely fashion.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Additional Fee - Class "B/Alternative" Lenders And Private Mortgages
        </div>
        <div className="type__subtitle">
          We charge an additional $100.00 plus GST and provincial sales tax 
          (where applicable) for mortgages that are from "B" Lenders or Private 
          Lenders. "B" Lenders generally are smaller financial institutions, 
          lending companies and investment companies that are not chartered 
          banks.
        </div>
        <div className="type__subtitle">
          We do not charge a premium for the large chartered Canadian banks and 
          class "A" lenders such as TD Canada Trust, Bank of Montreal, National 
          Bank, Royal Bank, Bank of Nova Scotia (Scotiabank), etc.
        </div>
        <div className="type__subtitle">
          The reason for this premium in fees is because smaller 
          lenders usually do not have physical branches, and usually ask for 
          many more requirements and documents to be processed by our law firm 
          as compared to the larger class A lenders. Our premium is to cover the 
          extra resources we need to invest for files with mortgages that 
          request more information than that of a standard file.
        </div>
        <div className="type__title">
          STATUS CERTIFICATES (FOR CONDOMINIUMS/STRATAS)
        </div>
        <div className="type__subtitle">
          If the property you are purchasing is a condominium/strata, we will 
          require a review of the Condominium/Strata Status Certificate as a 
          condition of closing set by your lender and Title Insurer. The Status 
          Certificate contains other documents and information, including copies 
          of the declaration, by-laws and rules which you should review 
          carefully as these documents govern the use of the unit and common 
          elements. If the Vendor does not provide a sufficiently recent Status 
          Certificate. you will be responsible for obtaining a current status 
          certificate and all associated costs with obtaining the status 
          certificate. If you are unable to request a Status Certificate, you 
          authorize DEEDED Law to order it on your 
          behalf (such disbursements will be added on to your account).
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: "600" }}>We DO NOT review</span> the 
          Declarations, By-Laws, Rules and Regulations or other documents 
          provided with the Certificate, unless we have specifically been 
          instructed to do so. Please review these documents yourself or consult 
          with a professional accountant or insurance agent with regard to the 
          sufficiency of the insurance or financial statements
        </div>
        <div className="type__title">
          NEW BUILDS - NEW HOME PRE-DELIVERY INSPECTION (if applicable)
        </div>
        <div className="type__subtitle">
          As part of certain New Home Warranty Programs, each purchaser may be 
          required to meet with the Builder’s Representative before closing to 
          conduct a Pre-Delivery Inspection (PDI) and complete a Certificate of 
          Completion and Possession. Please note, due to COVID-19, PDIs can now 
          happen virtually and after closing. During the PDI, you are to 
          identify any damage, product malfunctions and incomplete/missing 
          items. Be sure that the Builder’s Representative records these 
          conditions on the PDI inspection form to confirm that these issues 
          were existent prior to you taking possession of the property. If you 
          cannot assess something, as it has yet to be installed or completed, 
          this matter should also be noted on your PDI form. Our office will 
          require a copy of the signed Certificate of Completion and Possession 
          in order to close the transaction.
        </div>
        <div className="type__title">INSURANCE</div>
        <div className="type__subtitle">
          Your lender will require proof of home insurance prior to closing with 
          your new lender named as “loss payee” on the policy. Obtaining or 
          modifying your insurance policy is solely your responsibility. Any 
          closing delays and associated fees caused by not having adequate proof 
          of insurance are your responsibility.
        </div>
        <div className="type__title">INSURING TITLE TO YOUR PROPERTY</div>
        <div className="type__subtitle">
          Title insurance protects your ownership interest (i.e., title) of your 
          property from losses incurred as a result of unknown title defects or 
          other covered matters that exist at the time of your purchase but are 
          unknown to you at that time. Title insurance provides coverage for 
          losses arising from, but not limited to:
        </div>
        <div className="type__subtitle">Title Risks:</div>
        <div className="type__subtitle">
          <ul>
            <li>Someone else claiming an interest in your title.</li>
            <li>
              Fraud, forgery, and false impersonation affecting the validity of
              your title.
            </li>
            <li>
              Existing liens against the title including realty tax arrears and
              municipal utility charges.
            </li>
          </ul>
        </div>
        <div className="type__subtitle">Off-Title Risks:</div>
        <div className="type__subtitle">
          <ul>
            <li>Violations of municipal zoning by-laws.</li>
            <li>
              Many forms of encroachments onto the property or adjoining land.
            </li>
            <li>Existing work orders.</li>
            <li>Lack of legal access to the property.</li>
            <li>
              Unmarketability of land due to adverse matters that an up-to-date
              survey/Real Property Report (RPR)/Building Location Certificate
              would have revealed.
            </li>
          </ul>
        </div>
        <div className="type__subtitle">
          There are two types of title insurance policies. The Owner’s Policy 
          protects your title to the property. The Loan Policy to protect your 
          lender’s priority and enforceability of the mortgage. As a condition 
          of your mortgage, many lenders require a Loan Policy be purchased
        </div>
        <div className="type__subtitle">
          Title insurance is available for a low premium that is paid only once 
          at the time of closing. Coverage is valid for the duration of 
          ownership and the policy coverage amount is generally the amount you 
          paid for your property. Premium costs are often offset by the savings 
          from the reduced number of required searches your lawyer must 
          complete. Additionally, your title insurance policy can often satisfy 
          the lender’s requirement for an up-to-date property 
          survey/RPR/Building Location Certificate, saving you additional costs 
          (typically between $500.00 and $1,200.00).
        </div>
        <div className="type__subtitle">
          There are standard exclusions and exceptions from coverage contained 
          in all title insurance policies. These include:
        </div>
        <div className="type__subtitle">
          <ul>
            <li>Risks created, allowed, or agreed to by you.</li>
            <li>
              Risks that are known to you, but not to the title insurer, on the
              policy date
            </li>
            <li>Environmental matters, including mold and UFFI,</li>
            <li>First Nations land claims.</li>
            <li>Matters that result in no loss to you.</li>
            <li>
              Matters disclosed in a home inspection report obtained prior to
              closing.
            </li>
            <li>Functionality of home systems.</li>
            <li>
              Physical/structural conditions or defects regard-less of whether
              or not such conditions/defects affect the value or marketability
              of the property.
            </li>
          </ul>
        </div>
        <div className="type__subtitle">
          Based on the searches performed by your lawyer, there may be property 
          specific exceptions added to your policy.
        </div>
        <div className="type__subtitle">
          It is recommended that your transaction is completed by way of title 
          insurance, and in the event that we do not hear from you on the 
          contrary, we will proceed to order a policy on your behalf for 
          closing.
        </div>
        <div className="type__subtitle">
          We will be ordering a policy from either First Canadian Title, Stewart 
          Title or Chicago Title for closing. You are responsible for the costs 
          and associated taxes of a one-time premium for the title insurance 
          policy.
        </div>
        <div className="type__title">UTILITIES & PROPERTY TAXES</div>
        <div className="type__subtitle">
          You will need to contact property tax authorities and utility 
          companies (electricity, natural gas, hot water tank rental, water, 
          internet, phone, TV, and satellite) to create new accounts in your 
          name for closing, as it remains your responsibility to pay any amounts 
          owing and to update your account(s).
        </div>
        <div className="type__subtitle">
          With respect to Hot Water Tanks, it is your responsibility to contact 
          the relevant company and provide our office with the Assumption 
          Agreement (if applicable) 5 business days before closing.
        </div>
        <div className="type__title">KEYS</div>
        <div className="type__subtitle">
          We will receive the keys to your property on closing. We are unable to 
          release the keys to you until all the appropriate documents have been 
          registered with the local Land Registry Office and/or funds have been 
          received by the seller’s real estate professional. We cannot guarantee 
          when this will occur. While we assure you that we will do what we can 
          to ensure the timely closing of your purchase transaction, it is not 
          uncommon for the keys to not become available until close to 5:00 pm 
          on your closing date.
        </div>
        <div className="type__subtitle">
          As such, please ensure that you make the appropriate moving 
          arrangements to account for the delivery of your keys at that time of 
          day. When and where possible, we will arrange with your seller to 
          leave their keys in a lockbox on the property for your convenience and 
          as a courtesy to you. Such arrangements are highly dependent on 
          cooperation from the Sellers and their Real Estate professionals and 
          may be subject to local rules and regulations. DEEDED Law 
          will not be held responsible where such arrangements 
          cannot be made, or delays are caused, for any reason whatsoever. You 
          will be responsible for any costs associated with picking up your 
          keys.
        </div>
        <div className="type__title">INFORMATION WE NEED FROM YOU</div>
        <div className="type__subtitle">
          During the course of your closing, we will require information and 
          certain documents from you in order to facilitate a timely closing of 
          your transaction.
        </div>
        <div className="type__subtitle">
          You are responsible for the timely delivery of information and 
          documents to our office either by electronic, facsimile or couriering 
          documents. Any delays or additional fees, penalties, or interest 
          incurred due to unreasonable delays or withholding required documents 
          or information from you, shall be solely your responsibility.
        </div>
        <div className="type__title">REMOTE / VIRTUAL SIGNING</div>
        <div className="type__subtitle">
          Remote commissioning is an authentication and signature process for 
          taking affidavits and statutory declarations that uses audio-visual 
          technology.
        </div>
        <div className="type__subtitle">
          At DEEDED Law, we use secure remote 
          commissioning technology to make the signing process safe, easy and 
          secure. Shortly before your closing, we will arrange a day and time to 
          meet with you virtually using our remote commissioning technology. We 
          will need each person whose name will appear on the Mortgage and 
          Transfer to attend the signing, if applicable. If any person whose 
          name will appear on the Mortgage and Transfer will not be available at 
          that time, please advise us as soon as possible as alternative 
          arrangements may have to be made.
        </div>
        <div className="type__subtitle">
          In a remote commissioning procedure, you will be connected by live 
          video conference. The video and signing session will be recorded for 
          our records, along with information about your location, identity, and 
          other measures used to prevent fraud and protect all parties from 
          future disputes. Any recordings, or information captured during, 
          before, or after the remote commissioning session is the sole property 
          of DEEDED Law and shall become part of your 
          client file for record keeping purposes and to comply with 
          regulations.
        </div>
        <div className="type__subtitle">
          In order to attend a remote commissioning session, you will require 
          access to a computer, a high-speed internet connection and a 
          touch-screen device such as a mobile phone. If you do not have access 
          to required technology, please inform us immediately so that we can 
          seek alternative options.
        </div>
        <div
          className="type__title"
          style={{
            textDecoration: "none",
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          Additional Fee – More than one signing appointment
        </div>
        <div className="type__subtitle">
          Our fees include one virtual signing appointment and requires all 
          borrowers, guarantors or any other parties required to sign documents 
          to attend at the scheduled time for signing. Should one or more 
          parties fail to attend, are late to the signing meeting, fail to 
          produce valid identification, or are having technology issues that 
          cannot be resolved in the beginning of the signing meeting, you may be 
          charged for an additional signing appointment.
        </div>
        <div className="type__subtitle">
          Should you require a visit by a lawyer or commissioner of oaths to 
          your home or office in order to sign the documents, you may incur 
          additional fees.
        </div>
        <div className="type__title">IDENTIFICATION</div>
        <div className="type__subtitle">
          The Law Society of Ontario and your mortgage lender require us to 
          verify your identity. We must also ensure your matter doesn’t conflict 
          with another matter or client.
        </div>
        <div className="type__subtitle">
          At our meeting, we will require each person to 
          bring two pieces of identification, one of which must be 
          Canadian/Federal or Provincial government-issued photo identification 
          such as a driver's licence or passport. A {" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
            health card is not acceptable identification.{" "}
          </span>
          If you have any questions about what is an acceptable form of identification 
          or if you do not have Canadian/Federal or Provincial photo identification, 
          please contact us as soon as possible.
        </div>
        <div
          className="type__title"
          style={{
            textDecoration: "none",
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          An expired or non-valid identification will not be accepted.
        </div>
        <div
          className="type__title"
          style={{
            textDecoration: "none",
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          Client Identification and Verification
        </div>
        <div className="type__subtitle">
          DEEDED Law retains the services of a third-party to assist with the 
          Client Identification and Verification process {" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
            (“IDV”)
          </span> {" "}
          to comply with Law Society rules. All clients are required to complete the IDV process, 
          and if we are unable to comply with the IDV rules thereof for 
          any reason, the client will be required to meet in-person with a local 
          Notary or Lawyer to complete the IDV process. If we are unable to complete 
          the IDV process, we will no longer be able to act on the transaction.
        </div>
        <div className="type__subtitle">
        The Client is responsible for all fees associated with the IDV process, 
        including all third-party fees.
        </div>
        <div className="type__title">TERMINATION OF SERVICES</div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          By you, the client
        </div>
        <div className="type__subtitle">
          You have the right to terminate our services to you upon written 
          notice to our firm.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          By us, the Solicitor(s)
        </div>
        <div className="type__subtitle">
          Subject to our obligations to you to maintain proper standards of 
          professional conduct, we reserve the right to terminate our services 
          to you for good reasons which include, but are not limited to:
        </div>
        <div className="type__subtitle">
          <ul>
            <li>if you fail to cooperate with me in any reasonable request;</li>
            <li>if our continuing to act would be unethical or impractical;</li>
            <li>improper behavior or aggression;</li>
            <li>if there is a serious loss of confidence between us and you;</li>
            <li>if or retainer has not been paid;</li>
            <li>you are dishonest; or</li>
            <li>if you fail to pay our accounts when rendered.</li>
          </ul>
        </div>
        <div className="type__subtitle">
          If you terminate our services or we withdraw our services, you would 
          only have to pay fees, disbursements, and expenses up until the time 
          we stopped acting for you.
        </div>
        <div className="type__subtitle">
        We would also have to withdraw our services if we learned of a{" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
            conflict of interest
          </span>{" "}
          that would make it unethical for us to continue to act for you. A 
          conflict of interest occurs when what is best for one of the clients 
          of our firm somehow is not best for or hurts another of our clients. 
          If we have to withdraw our services for you because of a conflict of 
          interest, you will only have to pay our fees and expenses up to the 
          time we stopped acting for you.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Fees Collected On Terminated Or Cancelled Matters With Flat Rate Fees
        </div>
        <div className="type__subtitle">
          Please note for matters that have flat rate fees such as Real Estate 
          closings, we reserve the right to charge for our time and resources 
          spent up to the termination of the solicitor-client contract.
        </div>
        <div className="type__subtitle">
          Terminations may also occur upon a client’s failure to close as a 
          result of a lack of funds or other breach of contract or due to the 
          client not providing information and co-operation as necessary. DEEDED 
          Law shall have the right to charge for its 
          time and resources on files that are terminated as a result of client 
          negligence. For clarity, DEEDED Law shall 
          have the right to charge for its time and resources for if a flat rate 
          closing file fails to close for any reason.
        </div>
        <div className="type__title">USE OF TECHNOLOGY</div>
        <div className="type__subtitle">
          To facilitate a better closing experience, DEEDED Law
           uses certain proprietary and third-party technologies.
        </div>
        <div className="type__subtitle">
          DEEDED Law makes every attempt to keep its 
          content up-to-date and the information it provides as accurate as 
          possible. However, information provided by DEEDED Law 
          through its website or any applications should be used 
          solely for informational purposes. DEEDED Law
           makes no representations or warranties about the accuracy or 
           completeness of our content or the continued availability of our 
           content, except where required by law. All of the information provided 
           by DEEDED Law is "as is" and without 
           warranties of any kind, whether express or implied. DEEDED Law 
           does not represent or warrant that DEEDED Law 
           products or services will be error free, 
           except where required by law.
        </div>
        <div className="type__title">ELECTRONIC COMMUNICATION</div>
        <div className="type__subtitle">
          DEEDED Law Real Estate Group uses all reasonable means to 
          protect the security and confidentiality of e-mail information sent and 
          received. You acknowledge that you understand that email can never be 
          a secure means of communication and document exchange and that DEEDED 
          Law does not guarantee the security and 
          confidentiality of e-mail communication. You acknowledge that DEEDED 
          Law will not be liable for improper use 
          and/or disclosure of confidential information and you fully understand 
          the information provided to you regarding the risks of using e-mail 
          and you consent to receiving all documentation regarding this matter 
          via e-mail communications.
        </div>
        <div className="type__title">USE OF PERSONAL INFORMATION</div>
        <div className="type__subtitle">
          DEEDED Law is responsible for compliance 
          under the Personal Information Protection and Electronic Documents Act 
          (PIPEDA) These laws set out rules for how organizations collect, use 
          and disclose personal information. DEEDED Law 
          committed to being accountable for how it handles personal 
          information, as well as how it follows the rules and procedures 
          outlined in its privacy notice.
        </div>
        <div className="type__subtitle">
          DEEDED Law will only collect, use or disclose 
          personal information that is necessary to verify your identity, to 
          provide the requested legal services, or as required by law. You agree 
          DEEDED Law may disclose your personal 
          information to third parties including conveyancing software, 
          video-conferencing technology, title insurance providers and for any 
          such further purpose related to providing the legal services as set 
          out in this agreement. DEEDED Law ensures 
          that there is an agreement in place with any third parties that 
          ensures the organization understands and follows the same privacy 
          standards.
        </div>
        <div className="type__subtitle">
          You may withdraw your consent for the disclosure of your personal 
          information at any time. However, the withdrawal of such consent, may 
          result in us being unable to continue to provide legal services to 
          you.
        </div>
        <div className="type__title">
          TERMINATION OF USE OF OUR TECHNOLOGY
        </div>
        <div className="type__subtitle">
          DEEDED Law may terminate your access to this 
          Website or any of our proprietary and third-party applications at any 
          time with or without cause and without prior notice. In the event of 
          termination of such access, you acknowledge that any material, data 
          and information you have submitted to DEEDED Law 
          on this Website may be lost or may otherwise no longer be 
          available to you. Where required by law, DEEDED Law 
          will continue to make certain material available to you.
        </div>
        <div className="type__title">
          ALTERNATIVE ATTORNEY IN CASE OF ABSENCE
        </div>
        <div className="type__subtitle">
          The Client acknowledges that the Lawyer has entered into an arrangement with 
          PulseLaw Real Estate Group Law Corporation (“PulseLaw”) to provide continuity of 
          legal representation in the event of the Lawyer’s planned or unplanned absence, 
          including but not limited to vacation, illness, incapacity, or other circumstances 
          that prevent the Lawyer from fulfilling their professional duties.<br />
          In such instances, PulseLaw may assume responsibility for the Client’s legal matter, 
          ensuring continued representation and the timely handling of any legal requirements. 
          The Client agrees that PulseLaw and its designated lawyers may take necessary steps 
          to advance the Client’s interests in the real estate matter during the Lawyer’s absence.<br />
          The Lawyer will make reasonable efforts to notify the Client in advance of any planned 
          absences and confirm the temporary transition of representation to PulseLaw. In the 
          case of an unplanned absence, such as sudden illness, incapacity, or if the Lawyer’s 
          law corporation ceases to exist, PulseLaw may act as necessary without prior notice to 
          the Client to prevent any prejudice to the Client’s matter.
          This designation does not create a new retainer agreement between the Client and PulseLaw, 
          except to the extent necessary to ensure ongoing legal representation. Fees and costs will 
          continue to be governed by this retainer agreement, unless otherwise agreed upon in writing 
          between the Client and PulseLaw.<br />
          The Lawyer remains responsible for overseeing the transition of representation, except in 
          cases of extended incapacity, dissolution of the Lawyer’s law corporation, or other 
          unforeseen unavailability, in which case the Client may seek independent legal 
          representation if desired.
        </div>
        <div className="type__title">
          YOUR AGREEMENT TO THE RETAINER AGREEMENT FOR SOLICITOR AND CLIENT 
          RELATIONSHIP
        </div>
        <div className="type__subtitle">
          By checking the box, you are acknowledging that you have read this 
          retainer agreement in full and that you agree to abide by the terms 
          set out above.
        </div>
        <div className="type__subtitle">
          If you do not agree to this retainer agreement, there is no 
          solicitor-client relationship. Please advise us immediately of any 
          questions, concerns, comments or if you do not agree to this retainer 
          agreement.
        </div>
      </>
  );
};

export default TermsOfPurchaseON;
